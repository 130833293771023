<template>
    <div class="guide">
      <div class="title">{{ titleObj.title }}</div>
      <div class="desc">
        {{ titleObj.desc }}
      </div>
        <div class="tools" v-if="navList && !navList[3].is_unlock">
          <div class="tools-btn" @click="handleBuy">购买全册</div>
        </div>
        <div class="guide-con ">
            <ul>
                <template v-for="(item,n) in navList" v-if="n!=0">
                    <li v-if="item.is_unlock" :key="n" @click="navClick(item,n)" :class="item.learn_rate>0?'on':item.childs.length==item.learn_rate?'succ':''">
                        <van-icon name="arrow" />
                        <img class="succ-img" src="@/assets/img/succ.png" width="84" alt="">
                        <span class="jindu" style="font-size:14px" v-if="item.learn_rate>0">{{(item.learn_rate*100).toFixed(0)}}%</span>
                        <span class="jindu" v-else>{{n}}</span>
                        <p class="p1">第{{number[n]}}章：{{item.name}}</p>
                        <p class="p2">共{{item.childs.length}}个专题</p>
                        <!-- <p class="p2">共3个考点，{{item.childs.length}}个专题，关联分数12分</p> -->
                    </li>
                    <li v-else :key="n" class="unlock" @click="unlock(item)">
                        <i class="iconfont icongeneral-suoding"></i>
                        <span class="jindu">{{n}}</span>
                        <p class="p1">第{{number[n]}}章：{{item.name}}</p>
                        <p class="p2">共{{item.childs.length}}个专题</p>
                    </li>

                    <!-- <li class="on">
                        <van-icon name="arrow" />
                        <span class="jindu">2</span>
                        <p class="p1">第一章：行列式</p>
                        <p class="p2">共3个考点，8个专题，关联分数12分</p>
                    </li>
                    <li class="succ">
                        <span class="jindu"><van-icon  class="mt_10" name="success" /></span>
                        <p class="p1">第一章：行列式</p>
                        <p class="p2">共3个考点，8个专题，关联分数12分</p>
                    </li> -->
                </template>
            </ul>
            <p class="more">更多章节即将上线</p>
        </div>

        <van-action-sheet
            class="reset-sheet"
            v-model="showReset"
            :actions="actionsReset"
            @select="onSelectReset"
            cancel-text="取消"
            description="重置将清除已掌握的考点记录和本书学习进度"
            close-on-click-action
        />

        <van-dialog v-model="showBuy" title="">
            <br>
            <a href="https://mp.weixin.qq.com/s?__biz=Mzg5ODU5OTU0Mw==&mid=2247484029&idx=1&sn=026ba3d035bf31ae41bbc42073f3d3ee&chksm=c0615d65f716d473d64bcd503b1952bdd555ceb3c31f93b0bf7608f979652b08e4621ef1e322#rd">购买链接</a>
            <br>
            <br>
        </van-dialog>

        <propPay ref="propPay" />
    </div>
</template>

<script>
    import propPay from '@/components/pay.vue'
    // 章节导学
    export default {
        name:'guide',
        data() {
            return {
                number: ['零',"一","二","三","四","五","六","七","八","九"],
                showPopover: false,
                actions: [{ text: '重置进度' }],
                showReset: false, // 重置
                actionsReset: [{ name: '确认重置' },],
                query:{},
                showBuy: false,
            }
        },
        computed: {
            navList() {
                let query = this.$route.query;
                return this.$store.state.navList[query.name];
            },
            is_registed() {
                return this.$store.state.is_registed;
            },
            titleObj () {
              let query = this.$route.query;
              const textMap = {
                gaoshushang: {
                  title: '高等数学综合训练',
                  desc: '高数通用部分的经典题，精编提示和总结，适合基础和强化阶段'
                },
                xiandai: {
                  title: '线代基础过关(无限版)',
                  desc: '线性代数基础知识与运算训练，适合基础巩固'
                },
                gailv: {
                  title: '概率论基础过关',
                  desc: '概率论经典题精选，全面提升解题能力，适合基础/强化阶段'
                },
                xiandai_standard: {
                  title: '线代基础过关(标准版)',
                  desc: '线性代数经典题精选，全面提升解题能力，适合基础/强化阶段'
                }
              }
              return textMap[query.name]
            }
        },
        components: {
            propPay,
        },
        methods: {
          getActive(){
            let query = this.$route.query;
            let active;
            if(query.name.indexOf("gaoshu")>-1){
                active=1
            }else if(query.name.indexOf("xiandai")>-1){
                active=2
            }else if(query.name.indexOf("gailv")>-1){
                active=3
            }
            return active;
          },
          handleBuy () {
            wx.miniProgram.navigateTo({url: '/pages/buyPage/index?active=6'});
            // location.href = 'https://mp.weixin.qq.com/s?__biz=Mzg5ODU5OTU0Mw==&mid=2247484029&idx=1&sn=026ba3d035bf31ae41bbc42073f3d3ee&chksm=c0615d65f716d473d64bcd503b1952bdd555ceb3c31f93b0bf7608f979652b08e4621ef1e322#rd'
          },
          // handleTools (type) {
          //   if (type === 'free') {
          //     this.$router.push({
          //       path:'/topic',
          //       query: {
          //         name: '高数试用',
          //         number: 0,
          //         book: 'gaoshushang',
          //         is_try: true
          //       }
          //     });
          //   } else {
          //     this.$router.push({
          //       path:'/guide',
          //       query:{
          //         name: 'gaoshushang'
          //       }
          //     })
          //   }
          // },

            unlock(item){
              let active = this.getActive();

              wx.miniProgram.navigateTo({url: '/pages/buyPage/index?active='+active});
              return 
                // this.$toast('解锁观看，详见公众号');
                console.log('======>', item)
                if(this.is_registed){
                    let param = {
                        openid: localStorage.openid,
                        username: 'haha'
                    };
                    this.$api.register({param,method:'post'}).then(res=>{
                        if(res.wq_access_token){
                            localStorage.access_token = res.wq_access_token;
                            // location.reload();
                            if(res.is_new_unlock){
                                this.$dialog({ message: '本章解锁成功' });
                                this.init();
                                // setTimeout(() => {
                                //     location.reload();
                                // }, 100);
                            }else{
                                // this.$dialog({ message: '请购买本章！' });
                                // this.showBuy = true;
                                wx.miniProgram.navigateTo({url: '/pages/buyPage/index?active='+active});
                                // this.$refs.propPay.showBottom = true;
                                return
                                let query = this.$route.query;
                                if(query.name=='xiandai'){
                                    this.$dialog.confirm({ message: '请购买本章！' }).then(() => {
                                      // on confirm
                                      wx.miniProgram.navigateTo({url: '/pages/buyPage/index'})
                                    })
                                    .catch(() => {
                                        // on cancel
                                      wx.miniProgram.navigateTo({url: '/pages/buyPage/index'})
                                    });
                                }else{
                                  wx.miniProgram.navigateTo({url: '/pages/buyPage/index'})
                                }
                            }
                        }
                        else{
                            this.$toast(res.inner_mess + res.message);
                        }
                    }).catch(err=>{
                        this.$toast('err' + err.message);
                    });
                }else{
                    // this.$router.push('/token');

                    wx.miniProgram.navigateTo({url: '/pages/buyPage/index?active='+active});
                    // this.$refs.propPay.showBottom = true;
                }
            },
            test(){
                console.log(this.navList);
            },
            onSelect(action) {
                // this.$toast(action.text);
                this.showReset = true;
            },
            onSelectReset(action) {
                // http://123.57.47.165:56728/book_reset?book=gaoshushang
                this.showReset = false;
                let query = this.$route.query;
                let param = {
                    book: query.name
                };
                this.$api.book_reset({param},res=>{
                    console.log('zongjie',res);
                    this.$toast('重置成功！');
                });
            },
            navClick(item,n){
                let query = this.$route.query;
                let data = {
                    name: item.name,
                    number: this.number[n],
                    book: query.name
                }
                if(query.name == 'xiandai'){
                    data.navInfo = item;
                    this.$router.push({path:'/special', query: data});
                }else{
                    this.$router.push({path:'/topic', query: data});
                }

            },
            init(){
                let query = this.$route.query;
                this.query = query;
                console.log('qu', query);
                this.$store.commit('getNavList',query.name);
            }
        },
        async created () {
            this.init();
        },
    }
</script>

<style lang="less" scoped>
    .guide{
        padding: 24px 0;
        min-height: 100vh;
        background: #F7F8FB;
        .top{
            padding: 0 16px;
            display: flex;
            position: relative;
            .left{
                img{
                    width: 95px;
                    height: 126px;
                }
            }
            .right{
                text-align: left;
                .p1{
                    height: 48px;
                    font-size: 18px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 24px;
                }
                .p2{
                    height: 21px;
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: rgba(255, 255, 255, 0.5);
                    line-height: 21px;
                    span{
                        color: #F9C200;
                    }
                }
            }
            .van-popover__wrapper{
                position: absolute;
                right: 20px;
                top: 0px;
                width: 20px;
                height: 20px;
            }
            .setting{
                font-size: 16px;
                color: #FFFFFF;
            }
        }
        .title{
            height: 24px;
            font-size: 18px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #2A3155;
            line-height: 24px;
        }
        .more{
            margin-top: 72px;
            height: 20px;
            font-size: 14px;
            font-family: SourceHanSansSC-Normal, SourceHanSansSC;
            font-weight: 400;
            color: #BFC1CC;
            line-height: 20px;
        }
        &-con{
            width: 375px;
            min-height: 452px;
            // background: #FFFFFF;
            // border-radius: 0px 0px 12px 12px;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            overflow: auto;
            ul{
                padding-left: 36px;
                padding-top: 36px;
                li{
                    width: 319px;
                    height: 80px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
                    border-radius: 12px;
                    border: 2px solid #fff;
                    margin-bottom: 28px;
                    box-sizing: border-box;
                    padding-left: 20px;
                    padding-top: 12px;
                    text-align: left;
                    position: relative;
                    .p1{
                        height: 24px;
                        font-size: 16px;
                        font-family: SourceHanSansSC-Normal, SourceHanSansSC;
                        font-weight: 400;
                        color: #2A3155;
                        line-height: 24px;
                    }
                    .p2{
                        height: 20px;
                        font-size: 14px;
                        font-family: SourceHanSansSC-Normal, SourceHanSansSC;
                        font-weight: 400;
                        color: #9498AA;
                        line-height: 20px;
                    }
                    .van-icon-arrow{
                        position: absolute;
                        top: 32px;
                        right: 20px;
                        font-size: 14px;
                        color: #415FD5;
                    }
                    .icongeneral-suoding{
                        position: absolute;
                        top: 26px;
                        right: 20px;
                        font-size: 14px;
                        color: #9498AA;
                    }
                    .jindu{
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        background: #F9C200;
                        border-radius: 40px;
                        position: absolute;
                        top: -20px;
                        left: -20px;
                        font-size: 20px;
                        color: #FFFFFF;
                        font-family: SourceHanSansSC-Heavy, SourceHanSansSC;
                        font-weight: 800;
                        text-align: center;
                        &.on{
                            background: #B9BFD3;
                        }
                    }
                    .succ-img{
                        display: none;
                    }
                    &.unlock{
                        .jindu{
                            background: #B9BFD3;
                        }
                        .p1{
                            color: #9498AA;
                        }
                    }
                    &.succ{
                        border: 2px solid #15C392;
                        .jindu{
                            background: #15C392;
                            display: none;
                        }
                        .succ-img{
                            display: block;
                            position: absolute;
                            top: -30px;
                            left: -30px;
                            width: 60px;
                            height: 60px;
                        }
                    }
                    &.on{
                        border: 2px solid #415FD5;
                        .jindu{
                            background: #415FD5;
                        }
                    }
                }
            }
        }
        .reset-sheet{
            font-size: 16px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #FF4236;
        }
    }

    .guide {
      .title {
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #2a3155;
        line-height: 24px;
        margin-bottom: 4px;
      }

      .desc {
        height: 18px;
        font-size: 11px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #9498aa;
        line-height: 18px;
        margin-bottom: 4px;
      }

      .tools {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;

        &-btn {
          height: 20px;
          font-size: 14px;
          font-family: SourceHanSansSC-Bold, SourceHanSansSC;
          font-weight: bold;
          color: #415fd5;
          line-height: 20px;
        }

        &-line {
          height: 16px;
          width: 1px;
          margin: 2px 24px;
          background: #415fd5;
        }
      }
    }
</style>
